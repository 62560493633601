<template>
    <div class="wrapper-detail" v-for="(orderDetails, orderIndex) in ordersData" :key="orderIndex">
        <div class="wrapper-detail" v-for="(details, index) in orderDetails" :key="details.id">
            <div class="card-info">
                <div
                    class="wrapper-card-info__text visibility" 
                >
                    <span class="card-info__text">{{ $t('bezahlstatus') }}</span>
                    <span
                        class="card-info__text--payment-status"
                        :style="{ color: colorStatus(details.status) }"
                    >
                        {{ $t(details.status) }}
                    </span>
                </div>

                <div
                    class="wrapper-card-info__text"
                    v-if="index === 0 && orderDetails.length > 0"
                >
                    <span class="card-info__text card-info__text-new">
                         {{ $t('Gesamtbestellwert') }} CHF {{
                            orderDetails.reduce((total, details) => total + (Number.parseFloat(details.price) * Number(details.amount)), 0).toFixed(2)
                        }}
                    </span>
                </div>
            </div>

            <div class="card-goods-info">
                <img
                    class="card-goods-info__img"
                    :src="details.product_photo_path"
                    :alt="details.full_name"
                />
                <div class="sub-card-goods-info">
                    <div class="card-product-info">
                        <span class="card-info__text">{{ details.full_name }}</span>
                        <span class="card-info__text">{{ Number(details.amount) }}</span>
                        <div class="card-info__price">
                            <span class="card-info__text card-info__text-new">CHF {{ details.price }}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
  
<script setup lang="ts">
import { OrderDetailsStore } from "@/store/modules/StatusGroup/StatusGroupStore";
import { computed, defineProps, onMounted, onUnmounted } from "vue";
//import { useRoute } from "vue-router";

//const route = useRoute();

const props = defineProps({
    order_id: {
        type: Number,
    }
});

const statusStore = OrderDetailsStore(`${props.order_id}`);
const detailsData = computed(() => statusStore.results);

const colorStatus = (status: string) => {
    switch(status) {
        case 'COMPLETED':
        case 'NORMAL':
            return 'green'
        default:
            return '#B88500';
    }
}

onMounted(() => {
    if(props.order_id) {
        statusStore.getOrderDetails(props.order_id);
    }
});

onUnmounted(() => {
    statusStore.results = [];
});

const ordersData = computed(() => [detailsData.value]);
</script>

<style src="./CardInfoComponent.scss" lang="scss" />
