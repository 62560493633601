import { createI18n } from 'vue-i18n';
import { Deutsch } from './translate/Deutsch';
import { Italian } from './translate/Italian';
import { French } from './translate/French';

const exceptions = [
    'MwSt',
    'MwSt-Nummer',
    'maschinenserviceTextPart1',
    'payment2',
    'druckTextPart1',
    'druckTextPart2',
    'druckTextPart3',
    'druckDescriptionPart1',
    'druckDescriptionPart2',
    'druckDescriptionPart3',
    'druckDescriptionPart4',
    'druckDescriptionPart5',
    'druckDescriptionPart6',
    'druckDescriptionPart7',
    'druckDescriptionPart12',

];

const capitalizeFirstLetter = (text: string) => {
    if (!text || typeof text !== 'string') return text;
    return text.charAt(0).toUpperCase() + text.slice(1);
  };

const formatTranslations = (translations: Record<string, string>) => {
    const formatted: Record<string, string> = {};
    for (const key in translations) {
        if (exceptions.includes(key)) {
            formatted[key] = translations[key];
        } else {
            formatted[key] = capitalizeFirstLetter(translations[key]);
        }
    }
    return formatted;
};

const Language = {
    DE: formatTranslations(Deutsch),
    IT: formatTranslations(Italian),
    FR: formatTranslations(French),
};

export const LanguageKeys = Object.keys(Language);

export const i18n = createI18n({
    locale: 'DE',
    legacy: false,
    messages: Language,
});
