<template>
    <div class="wrapper-login">
        <div class="close-button" @click="popupStore.changeOpenPopupStore(false)" />
        <span class="login-title">
            {{ isResetPassword ? $t('Passwort zurücksetzen') : $t('melden sie sich in ihrem konto an') }}
        </span>
        
        <form v-if="!isResetPassword" class="login-form" @submit.prevent="handleSubmitLogin">
            <div class="wrapper-input-login">
                <span class="sub-login-title">{{ $t('email oder Kundennummer') }}</span>
                <Input
                    :max="55"
                    name="email"
                    :placeholder="$t('email_login')"
                    v-model="values.email"
                />
                <span class="input-group__error">{{ errors.email }}</span>
            </div>
            <div class="wrapper-input-login">
                <span class="sub-login-title">{{ $t('password') }}</span>
                <inputPassword
                    :max="55"
                    name="password"
                    :placeholder="$t('password')"
                    v-model="values.password"
                />
                <span class="input-group__error">{{ errors.password }}</span>
            </div>
            <div class="wrapper-login-reset">
                <div class="sub-wrapper-login-reset">
                    <span class="login-text">{{ $t('nicht registriert?') }}</span>
                    <ButtonWithoutBackgroundComponent>
                        {{ $t('ein Konto erstellen') }}
                    </ButtonWithoutBackgroundComponent>
                </div>
                <ButtonWithoutBackgroundComponent type="button" @click="toggleResetPassword">
                    {{ $t('passwort vergessen?') }}
                </ButtonWithoutBackgroundComponent>
            </div>
            <ButtonBackgroundComponent class="login-button" type="submit">
                {{ $t('melden sie sich in ihrem konto an') }}
            </ButtonBackgroundComponent>
            <span class="input-group__error">{{ errors.detail }}</span>
        </form>
        
        <form v-if="isResetPassword" class="" @submit.prevent="handleResetPasswordSubmit">
            <div class="wrapper-input-login">
                <span class="sub-login-title">{{ $t('Geben Sie Ihre E-Mail-Adresse ein') }}</span>
                <input 
                style="min-height: 46px;"
                    class="input-group"
                    type="email"
                    name="resetEmail"
                    :placeholder="$t('email_login')"
                    v-model="resetEmail"
                    required
                />
                
                <span class="input-group__error">{{ resetErrors.email }}</span>
            </div>
            <div class="wrapper-login-reset">
                <ButtonWithoutBackgroundComponent type="button" @click="toggleResetPassword">
                    {{ $t('Zurück') }}
                </ButtonWithoutBackgroundComponent>
                <ButtonBackgroundComponent class="reset-button" type="submit" :disabled="loadingForm">
                    {{ loadingForm ? $t('Wird gesendet...') : $t('Passwort zurücksetzen') }}
                </ButtonBackgroundComponent>
            </div>
        </form>
    </div>
</template>

<script setup lang="ts">
import { ref } from "vue";
import axios from "axios";
import { useI18n } from "vue-i18n";
import ButtonBackgroundComponent from "@/components/ui/buttonGroup/buttonBackgroundGroup/buttonBackgroundComponent.vue";
import ButtonWithoutBackgroundComponent from "@/components/ui/buttonGroup/buttonWithoutBackgroundGroup/buttonWithoutBackgroundComponent.vue";
import { useTokenStore } from "@/store/modules/TokenOperationStoreGroup/TokenOperation";
import { usePopupStore } from "@/store/modules/PopupStoreGroup/PopupStore";
import inputPassword from "@components/ui/inputGroup/inputPasswordComponent/inputPasswordComponent.vue";
import { loginGroupSchema } from '@/yup/loginGroupSchema/loginSchema';
import { ILoginGroupSchema } from '@/yup/loginGroupSchema/type';
import { useForm } from "vee-validate";
import { useRouter } from "vue-router";
import { getAccountData } from "@/utils/getUserDataUtils/getUserDataUtils";
import { useFavoriteStore } from "@/store/modules/FavoriteStoreGroup/FavoriteStore";
import Input from "@components/ui/inputGroup/inputComponent/inputComponent.vue";

const { t } = useI18n();
const popupStore = usePopupStore('login');
const tokenStore = useTokenStore();
const router = useRouter();
const favoriteStore = useFavoriteStore();

const isResetPassword = ref(false);

const { handleSubmit, setErrors, errors, values } = useForm<ILoginGroupSchema>({
    initialValues: {
        email: '',
        password: '',
        detail: '',
    },
    validationSchema: loginGroupSchema,
});

const resetEmail = ref('');
const resetErrors = ref<{ email?: string }>({});
const loadingForm = ref(false);

const API_URL = `${process.env.VUE_APP_SERVER}api/users/reset_password/`;

const handleSubmitLogin = handleSubmit(async (formValues) => {
    try {
        await tokenStore.userLogin(formValues);
        popupStore.open = false;
        await router.push({ name: 'mein_konto' }).then(() => {
        }).catch((err) => {
            console.error(err);
        });
        await getAccountData();
        await favoriteStore.fetchFavoriteCards();
    } catch (error: any) {
        let parsedErrors: Record<string, string> = {};
        try {
            parsedErrors = JSON.parse(error.request.response || '{}');
        } catch (parseError) {
            parseError
        }
        setErrors(parsedErrors);
    }
});

const toggleResetPassword = () => {
    isResetPassword.value = !isResetPassword.value;
    resetErrors.value = {};
    resetEmail.value = '';
};

const handleResetPasswordSubmit = async () => {
    if (!resetEmail.value) {
        alert(t('Bitte geben Sie Ihre E-Mail-Adresse ein.'));
        return;
    }
    try {
        loadingForm.value = true;
        const response = await axios.put(API_URL, {
            email: resetEmail.value,
        });
        if (response.data) {
            if (typeof response.data === 'object') {
                alert(JSON.stringify(response.data.success, null, 2));
            } else {
                alert(response.data); 
            }
        }
    } catch (error: any) {
        console.error( error);
        if (error.response?.data) {
            alert(JSON.stringify(error.response.data, null, 2));
            resetErrors.value.email = error.response.data.email || error.response.data.detail;
        } else {
            alert(t('Es ist ein unbekannter Fehler aufgetreten.'));
        }
    } finally {
        loadingForm.value = false;
    }
};
</script>

<style lang="scss" src="./PopupLoginComponent.scss" />
<style lang="scss" src="@/components/ui/inputGroup/inputComponent/inputComponent.scss" />
