<template>
  <footer class="footer">
    <img src="@/assets/images/footer/team.webp" alt="roth schunbedarf team" v-if="route.name === 'team'"/>
    <div class="sub-footer">
      <div class="footer__column-img">
        <div class="footer__column">
          <span class="footer__column-title">
            {{ $t("rudolf roth ag") }}
          </span>
          <span class="footer__column-sub-title">
            {{ $t("schuh - und orthopädiebedarf") }}
          </span>
          <router-link to="/" class="footer__column-text">
            <img src="@/assets/images/icons/Logo.svg" alt="Logo" />
          </router-link>
        </div>
        <div class="footer__column">
          <a :href="filePath" class="footer__column-text" download="Impressum.docx">
            {{ $t("impressum") }}
          </a>

          <span class="footer__column-text"> © 2024 {{ $t("rudolf roth ag") }} </span>
        </div>
      </div>

      <div class="footer__column">
        <span class="footer__column-title">{{ $t("kontakt") }}</span>
        <span class="footer__column-text">Bodmerstrasse 15</span>
        <span class="footer__column-text">CH-3645 Gwatt - Thun</span>
        <span class="footer__column-text">Tel. 033 336 47 57</span>
        <span class="footer__column-text">Fax 033 336 47 26</span>
        <span class="footer__column-text">info@rothag.ch</span>
      </div>

      <div class="footer__column">
        <span class="footer__column-title">
          {{ $t("Telefondienst") }}
        </span>
        <span class="footer__column-sub-title">
          {{ $t("montag - donnerstag") }}
        </span>
        <span class="footer__column-text">
          {{ $t('7.45 Uhr- 12.00 Uhr') }}
        </span>
        <span class="footer__column-text">
          {{ $t('13.00 Uhr - 17.15 Uhr') }}
        </span>
        <span class="footer__column-sub-title">
          {{ $t("freitag") }}
        </span>
        <span class="footer__column-text">
          {{ $t('7.45 Uhr- 12.00 Uhr') }}
        </span>
        <span class="footer__column-text">
          {{ $t('13.00 Uhr - 16.45 Uhr') }}
        </span>
 


        <span class="footer__column-sub-title">
          {{ $t("samstag und sonntag") }}
        </span>
        <span class="footer__column-text">
          {{ $t("geschlossen") }}
        </span>
      </div>

      <div class="footer__column">
        <span class="footer__column-title">
          {{ $t("angebot") }}
        </span>

        <router-link class="footer__column-text link" to="/angebot/kataloge">
          {{ $t("kataloge") }}
        </router-link>

        <router-link class="footer__column-text link" to="/angebot/maschinenservice">
          {{ $t("maschinenservice") }}
        </router-link>

        <router-link class="footer__column-text link" to="/angebot/3d_druck">
          {{ $t("3D druck") }}
        </router-link>

        <router-link
          class="footer__column-text link"
          to="/angebot/workshop_and_schuhlungen"
        >
          {{ $t("workshop & schuhlungen") }}
        </router-link>
      </div>

      <div class="footer__column">
        <span class="footer__column-title link">
          {{ $t("uber uns") }}
        </span>
        <router-link class="footer__column-text link" to="/uber_uns/team">
          {{ $t("team") }}
        </router-link>

        <router-link class="footer__column-text link" to="/uber_uns/kontakt">
          {{ $t("kontakt") }}
        </router-link>
      </div>
    </div>
  </footer>
</template>

<script setup lang="ts">
import { useRoute } from "vue-router";
const filePath = new URL('@/assets/doc/impressum.docx', import.meta.url).href;
const route = useRoute();
</script>

<style lang="scss" src="./FooterComponent.scss" />
