import { Translate } from "./type";

export const Deutsch:Translate = {
    'email is a required field': "E-Mail ist ein Pflichtfeld",
    'password is a required field': "Passwort ist ein Pflichtfeld",
    'copy address': 'Adresse kopieren',
    'Workshop and Schuhlungen': 'Workshop und Schulungen',
    kontakt: 'Kontakt',
    partner: 'Partner',
    geschichte: 'Geschichte',
    team: 'Team',
    'uber uns': 'Über uns',
    'workshop & schuhlungen': 'Workshops & Schulungen',
    '3D druck': '3D-Druck',
    maschinenservice: 'Maschinenservice',
    kataloge: 'Kataloge',
    angebot: 'Angebot',
    geschlossen: 'Geschlossen',
    uhr: 'Uhr',
    'samstag und sonntag': 'Samstag und Sonntag',
    freitag: 'Freitag',
    'montag - donnerstag': 'Montag - Donnerstag',
    impressum: 'Impressum',
    'schuh - und orthopädiebedarf': 'Schuh - und Orthopädiebedarf',
    'rudolf roth ag': 'Rudolf Roth AG',
    'Anmelden oder Login beantragen': 'Anmelden oder Login beantragen',
    logout: 'Logout',
    webshop: 'Webshop',
    schuhlungen: 'Schulungen',
    'unser team': 'Unser Team',
    'mein konto': 'Mein Konto',
    'erledigte bestellungen': 'Erledigte Bestellungen',
    'pendente bestellungen': 'Pendente Bestellungen',
    pendente: 'Pendente Bestellungen',
    erledigte: 'Erledigte Bestellungen',
    rückgaben: 'Rückgaben',
    'defekt melden': 'Reklamation',
    accountangaben: 'Accountangaben',
    'änderungen speichern': 'Änderungen speichern',
    rechnungsadresse: 'Rechnungsadresse',
    'plz': 'PLZ',
    'ein land': 'Land',
    'die stadt': 'Ort',
    adresse: 'Adresse',
    kundennummer: 'Kundennummer',
    email: 'Email',
    'kennwort ändern': 'Kennwort ändern',
    'neue adresse hinzufügen': 'Neue Adresse hinzufügen',
    kontodetails: 'Kontodetails',
    firma: 'Firma',
    kontaktperson: 'Kontaktperson',
    telefonnummer: 'Telefonnummer',
    'zusatzliche telefonnummer': 'Zusätzliche Telefonnummer',
    'gln-nummer': 'GLN-Nummer',
    'mwst-nummer': 'MwSt-Nummer',
    lieferadresse: 'Lieferadresse',
    'bestellung wiederholen': 'bestellung wiederholen',
    bezahlstatus: "bezahlstatus",
    gesamtbetrag: "gesamtbetrag",
    size: "größe",
    colour: "farbe",
    'melden sie sich in ihrem konto an': 'Melden Sie sich bei Ihrem Konto an',
    email_login: 'email',
    'email oder Kundennummer': 'email oder Kundennummer',
    password: 'passwort',
    'nicht registriert?': 'nicht registriert?',
    'ein Konto erstellen': 'ein Konto erstellen',
    'passwort vergessen?': 'passwort vergessen?',
    'leer': 'leer',
    'wird geladen': 'wird geladen',
    "willkürlich": "willkürlich",
    "festgelegt": "festgelegt",
    "min length 1": "Mindestlänge 1",
    'bilder hochladen': 'bilder hochladen',
    'bestellnummer': 'bestellnummer',
    'defektbeschreibung': 'Beschreibung',
    'defektbeschreibung1' : 'Beschreibung',
    delete: 'delete', 
    'in den warenkorb': 'In den Warenkorb',
    'wonach suchst du?': 'wonach suchst du?',
    category: 'kategorie',
    'leerer warenkorb': 'Warenkorb Leeren',
    'zurück zum einkaufen': 'Zurück zum Einkaufen',
    bestellen: 'bestellen',
    'zum warenkorb hinzufügen': 'In den Warenkorb',
    'menge auf lager':'menge auf lager',
    favoritinnen: 'Merkliste',
    kasse: 'Bestellung',
    versandart: 'versandart',
    lieferung: 'lieferung',
    'Lieferadresse wählen': 'Lieferadresse wählen',
    'Andere Adresse': 'Andere Adresse', 
    rezension: 'Bestellübersicht',
    zahlung: 'Bestellabschluss',
    "We’re on it!": "Vielen Dank für Ihre Bestellung!",
    'Your payment has been received and you\'ll get notifications for your order\'s state.': 'Ihre Bestellung Nr. ',
    'payment2': ' wurde erfolgreich erstellt. Sie werden Benachrichtigungen zum Status Ihrer Bestellung erhalten.',
    'Zurück zum Einkaufen': 'Zurück zum Shop',
    'Something went wrong': 'Etwas ist schiefgelaufen',
    'Lorem ipsum dolor sit amet consectetur. Quisque arcu amet pretium lectus viverra sit.': 'Lorem ipsum dolor sit amet consectetur. Quisque arcu amet pretium lectus viverra sit.',
    "versand": "Versand",
    "post": "Post",
    "warenkorb": "Warenkorb",
    "Total": "Bild",
    "List": "Liste",
    "Price": "Listenpreis",
    "Quantity": "Menge",
    "Total Price": "Gesamtpreis",
    "color": "Farbe",
    "Rechnung erhalten per": "Rechnung erhalten per",
    "Versandart": "Versandart",
    "List of products": "Produktliste",
    "Photo": "Bild",
    "Warenkorb": "Warenkorb",
    'Unsere Partner': 'Unsere Partner',
    'Schulungen': 'Schulungen',
    Kataloge: 'Kataloge',
    Hauptkatalog: 'Hauptkatalog',
    Gummi: 'Gummi',
    Orthopädie: 'Orthopädie',
    "Leder": "Leder",
    "Chemie": "Chemie",
    "Beschläge": "Beschläge",
    "Schuhpflege": "Schuhpflege",
    "Werkzeuge": "Werkzeuge",
    "Maschinen": "Maschinen",
    Firma: "Firma",
    vorname: "Vorname",
    "Treten sie mit uns in kontakt": "Treten Sie mit uns in Kontakt",
    nachname: "Nachname",
    strasse: "Strasse",
    'nr.': "Nr.",
    'nr': "Nr.",
    postcode: "Post",
    ort: "Ort",
    phone: "Telefon",
    "Sie uns Ihr anliege": "Ihre Nachricht",
    'Bitte schildern Sie uns Ihr anliegen': "Bitte schildern Sie uns Ihr Anliegen",
    "Rudolf Roth AG": "Rudolf Roth AG",
    "Schuh - und Orthopädiebedarf": "Schuh- und Orthopädiebedarf",
    "Telefondienst": "Telefondienst",
    "Montag - Donnerstag": "Montag - Donnerstag",
    Freitag: "Freitag",
    "7.45 Uhr- 12.00 Uhr": "7.45 Uhr - 12.00 Uhr",
    "13.00 Uhr - 17.15 Uhr": "13.00 Uhr - 17.15 Uhr",
    "13.00 Uhr - 16.45 Uhr": "13.00 Uhr - 16.45 Uhr",
    "Samstag und Sonntag": "Samstag und Sonntag",
    Geschlossen: "Geschlossen",
    Senden: "Senden",
    "Maschinenservicek": "Maschinenservice",
    "Ein Maschinenservice-Anforderungsformular": "Formular für Maschinenservice-Anforderung",
    "name": "Name",
    "maschinenbezeichnung": "Maschinenbezeichnung",
    "Maschinennummer": "Maschinennummer",
    "Problem Beschreibung": "Problembeschreibung",
    "Anfrage": "Anfrage",
    "Maschinen service": "Maschinenservice",
    "Workshop and Schulungen": 'Workshop und Schulungen',
    kataloge_1: 'Falls Sie in unserem Onlineshop nicht fündig werden, können Sie in den Katalogen unserer Partner nach weiteren Optionen suchen. Wir bestellen die benötigten Artikel gerne direkt für Sie. Für Sonderanfertigungen schauen Sie bitte im Katalog der Marke Rudolf Roth AG nach und wenden Sie sich bei besonderen Wünschen direkt an uns.',
    'Anfrageformular für einen Workshop': 'Anfrageformular für einen Workshop',
    'Anfrageformular für 3D-Druck': 'Anfrageformular für 3D-Druck',
    "Geschichte_1": "Seit 1949 steht die Rudolf Roth AG als traditionsreiches Familienunternehmen für höchste Qualität und hervorragenden Kundenservice. Von Beginn an war es unser Ziel, nicht nur durch hochwertige Produkte, Marken und Maschinen zu überzeugen, sondern auch durch das Engagement und die Leidenschaft, welche in unserer Unternehmenskultur verwurzelt sind. Unser Fachwissen aus drei Generationen fliesst in jedes Detail ein und macht uns zu einem zuverlässigen Partner für orthopädische Werkstätten, Schuhmacher, sowie Schuhfach- und Sportgeschäfte.",
    "Geschichte_2": "Besonders wichtig ist uns partnerschaftliche Zusammenarbeit auf Augenhöhe, individueller Service und wir streben langfristige Kundenbeziehungen an. Von unserem Standort in Gwatt-Thun aus betreuen wir Kunden vorwiegend schweizweit, stehen ihnen mit Fachberatung und vor Ort-Unterstützung zur Seite und bieten Leistungen wie Produktverkauf, Reparaturen und Revisionen an. Als eines der schweizweit führenden Grosshandelsunternehmen in unserem Bereich steht unser Name auch für zukunftsweisende Innovation und Nachhaltigkeit. Wir bieten unter anderem 3D-Druck, 3D-Konstruktion und Recycling von EVA Aufbau- und Fräsmaterial für unsere Kunden an.",
    "Geschichte_3": "Ein besonderer Dank gilt unseren langjährigen Kunden, die uns seit Jahrzehnten ihr Vertrauen schenken. Sie inspirieren uns, auch in Zukunft mit Leidenschaft und Einsatz für Qualität und Service einzustehen. Wir freuen uns darauf, auch in den kommenden Jahren als Familienunternehmen Ihr verlässlicher Partner zu sein - mit Herz, Erfahrung, Tradition und Innovation.",
    'Ihr Warenkorb': 'Ihr Warenkorb',
    'Nettobetrag': 'Nettobetrag',
    'MWST':'MwSt',
    'Stärke':'Stärke',
    'Shore':'Shore',
    'Maßeinheiten':'Maßeinheiten',
    'WAITING': 'WARTEND',
    'OPENED': 'GEÖFFNET',
    'ISSUED': 'AUSGESTELLT',
    'CANCELED': 'STORNIERT',
    'CLOSED': 'GESCHLOSSEN',
    'NORMAL': 'NORMAL',
    'COMPLETED': 'Abgeschlossen',
    welcomeText: "Willkommen auf unserer Seite 'Schulungen'!",
    welcomeDescriptionPart1: "Wir freuen uns, Ihnen Workshops und Schulungen nach Ihren Bedürfnissen anzubieten.  Ob Sie Ihre Klebstoffanwendung optimieren möchten, sehen welche Möglichkeiten es mit modernem Fräs- und Aufbaumaterial gibt, oder lernen, wie man 3D-gedruckte Orthesen anfertigt – bei uns sind Sie an der richtigen Adresse. Unser Ziel ist es, Ihnen das Wissen und die Fähigkeiten zu vermitteln, sicher und kompetent mit unseren Produkten und Dienstleistungen zu arbeiten, unabhängig von Ihrem Erfahrungsstand. Gerne kommen häufig auch die Anwendungstechniker von Renia, Topy und anderen Partner zusammen mit uns zu Ihnen. Zusätzlich bieten wir auch Verkaufsschulungen im Bereich Pflegeprodukte für Schuh- und Sportgeschäfte an. Sie möchten gerne mehr Umsatz mit Zusatzverkäufen in Ihrem Geschäft generieren? Kein Problem!",
    welcomeDescriptionPart2: "Um Ihre Wünsche bestmöglich berücksichtigen zu können, bitten wir Sie, unser Online-Formular auszufüllen. Teilen Sie uns darin Ihren Namen, gegebenenfalls den Firmennamen, Ihre Telefonnummer, E-Mail-Adresse und Ihre Adresse mit. Nutzen Sie das Nachrichtenfeld, um uns mitzuteilen, welche Schulung Sie interessiert oder welche spezifischen Themen Sie vertiefen möchten. Sollten Sie daran interessiert sein, für die Schulungen Credits zu erhalten, informieren wir Sie gerne über die entsprechenden Möglichkeiten.",
    welcomeDescriptionPart3: "Wir freuen uns darauf, Sie auf Ihrem Lernweg zu unterstützen und gemeinsam mit Ihnen Ihre Expertise auszubauen!",
    'Umsatz im Bereich Pflegeprodukte mit einer Verkaufsschulung steigern':'Ich möchte meinen Umsatz im Bereich Pflegeprodukte mit einer Verkaufsschulung steigern',
    welcomeDescriptionPart4: '',
    druckTextPart1:'Willkommen auf unserer Seite für 3D-Druck-Anfragen speziell für Orthesen! Wir bieten individuell angepasste Orthesen, die mit Präzision und Sorgfalt gefertigt werden. Um den Prozess zu starten, füllen Sie bitte das Formular auf dieser Seite aus. So stellen wir sicher, dass wir alle notwendigen Informationen haben, um eine Lösung zu entwickeln, die perfekt auf Ihre Bedürfnisse abgestimmt ist.',
    druckTextPart2: 'Bitte geben Sie Ihren vollständigen Namen, Ihre Telefonnummer, Ihre E-Mail-Adresse sowie Ihre Adresse an. Falls Sie im Namen eines Unternehmens handeln, ergänzen Sie bitte den Firmennamen',
    druckTextPart3: 'Nutzen Sie das Nachrichtenfeld, um Details zu Ihrer Anfrage zu beschreiben. Teilen Sie uns z. B. den Verwendungszweck der Orthese oder besondere Anforderungen mit.',
    druckText: 'Materialoptionen',
    druckDescriptionPart1: 'Wählen Sie aus den folgenden Materialien das passende für Ihre Orthese:',
    druckDescriptionPart2: 'Leicht, flexibel und langlebig — ideal für Anwendungen mit geringer Belastung.',
    druckDescriptionPart3: 'Robust, schlagfest und lebensmittelecht — optimal für den täglichen Gebrauch.',
    druckDescriptionPart4: 'Elastisch, flexibel und verschleissfest — perfekt für dynamische oder weiche Orthesenkomponenten.',
    druckDescriptionPart5: 'Mit Kohlenstofffasern verstärkt für maximale Festigkeit und Steifigkeit — ideal für hochbelastbare Anwendungen.',
    druckDescriptionPart6: 'Falls Sie bereits eine gescannte STL-Datei der betreffenden Extremität besitzen, können Sie diese direkt hochladen. Wenn Sie keinen Scan haben, teilen Sie uns dies bitte in der Nachricht mit. Wir setzen uns dann mit Ihnen in Verbindung, um einen Termin für den Scan zu vereinbaren.',
    druckDescriptionPart7: 'Nach Eingang Ihrer Anfrage wird unser Team diese prüfen und sich zeitnah mit Ihnen in Verbindung setzen, um die Details zu besprechen und die nächsten Schritte zu planen.',
    druckDescriptionPart8: 'PP (Polypropylen):',
    druckDescriptionPart9: 'TPU (Thermoplastisches Polyurethan):',
    druckDescriptionPart10: 'PETG (Polyethylenterephthalat Glykol):',
    druckDescriptionPart11: 'PETG-Carbon:',
    druckDescriptionPart12: 'Vielen Dank, dass Sie sich für unseren 3D-Druckservice entschieden haben. Wir freuen uns darauf, eine massgeschneiderte Orthese für Sie zu erstellen, die perfekt auf Ihre Anforderungen abgestimmt ist!',
    HARDO: 'HARDO',
    maschinenserviceTextPart1: 'Bitte füllen Sie das folgende Formular aus, wenn Sie ein Problem mit Ihrer Maschine haben, sei es eine Störung, ein defektes Teil oder ein anstehender Filterservice. Teilen Sie uns den Namen der Maschine, Ihre Kontaktdaten und eine detaillierte Beschreibung des Problems mit. Falls möglich, laden Sie relevante Bilder hoch, um uns bei der Einschätzung zu unterstützen. Unser Team wird Ihre Anfrage umgehend prüfen und sich zeitnah mit Ihnen in Verbindung setzen, um einen Termin mit einem unserer Techniker zu vereinbaren.',
    Bestellung: 'Bestellung',
    'Auf-lager': 'Auf Lager',
    Materialoptionen:'Materialoptionen',
    PP: 'PP',
    TPU: 'TPU',
    PETG: 'PETG',
    'PETG-Carbon':'PETG-Carbon',
    Gesamtbestellwert: 'Gesamtbestellwert',
    'phasing out message': 'Solange Vorrat',
    'phasing out message1': '',
    success: 'Formular erfolgreich übermittelt!',
    'success-description' : 'Unsere Spezialisten werden sich in Kürze mit Ihnen in Verbindung setzen',
    'back_to_shop': 'Zurück zum Shop',
    Rabattpreis: 'Ihr Preis',
    wrong: 'Es isch öppis falsch gange',
    reset_password: "Passwort zurücksetzen",
    'confirm new password': "Neues Passwort bestätigen",
    vom: 'vom',
team_profession_gl_sales_management: "GL, Leitung Verkauf",
team_profession_gl_technical_sales: "GL, Verkauf, Leitung Technik",
team_profession_gl_admin_sales: "GL, Verkauf, Leitung Administration",
team_profession_admin_accounting: "Administration, Buchhaltung",
team_profession_sales: "Verkauf",
team_profession_admin: "Administration",
team_profession_logistics_technical: "Lagerbewirtschaftung, Technik",
team_profession_3d_construction_production: "3D-Konstruktion, Produktion",
team_profession_consulting_logistics: "Fachberatung, Logistik",
team_profession_project_management: "Projektmanagement",
team_profession_logistics_production: "Logistik, Produktion",
team_profession_workshop_service: "Werkstatt, Service",
agree: 'Mit dieser Bestellung akzeptieren Sie unsere',
agree1: 'Allgemeinen Geschäftsbedingungen',
agree2: 'und ',
agree3: 'Datenschutzerklärung',
tel: 'tel',
fax: 'fax',
problem: 'Problembeschreibung',
no_file_available: 'nicht zum Download verfügbar',
your_message_top: 'Ihre Nachricht',
your_message: 'Bitte schildern Sie uns Ihr Anliegen',
'Passwort zurücksetzen': 'Passwort zurücksetzen',
'Geben Sie Ihre E-Mail-Adresse ein': 'Geben Sie Ihre E-Mail-Adresse ein',
Zurück: 'Zurück',
'Wird gesendet...': 'Wird gesendet...',
'Ihr Nachricht': 'Ihre Nachricht',
'need choice': 'Sie müssen E-Mail oder Post auswählen',
};