<template>
  <div class="wrapper-card" v-for="statusData in statusStore.results" :key="statusData.id">
    <div class="card-title">
      <span>
        <span :style="{ color: colorStatus(statusData.status) }">
          {{ $t(textStatus(statusData.status)) }}
        </span>
        {{ statusData.number }} {{ $t('vom').toLowerCase() }} {{ statusData.date }}
      </span>
      <ButtonBackgroundComponent
        class="card-button"
        @click="repeatOrder(statusData.id)"
      >
        {{ $t('bestellung wiederholen') }}
      </ButtonBackgroundComponent>
    </div>

    <CardInfoComponent :order_id="statusData.id" />

    <PopupComponent :name="popup.name">
      <div class="wrapper-login">
        <div
          class="close-button"
          @click="popupStore.changeOpenPopupStore(false)"
        />
        <span class="login-title">{{ popupStore.message }}</span>
      </div>
    </PopupComponent>
  </div>
</template>

<script setup lang="ts">
import { reactive } from 'vue';
import { StatusGroupStore } from "@/store/modules/StatusGroup/StatusGroupStore";
import { usePopupStore } from "@/store/modules/PopupStoreGroup/PopupStore";
import { useShoppingCartStore } from '@/store/modules/ShoppingCartStoreGroup/ShoppingCartStore';

import ButtonBackgroundComponent from "@components/ui/buttonGroup/buttonBackgroundGroup/buttonBackgroundComponent.vue";
import CardInfoComponent from "@components/StatusCardGroup/CardInfoComponent/CardInfoComponent.vue";
import PopupComponent from "@components/PopupGroup/PopupComponent.vue";

const popup = reactive({ name: 'repeat' });
const popupStore = usePopupStore(popup.name);
const statusStore = StatusGroupStore();

const shoppingCartStore = useShoppingCartStore();

const textStatus = (status: string) => {
  switch (status) {
    case 'WAITING':
      return 'Bestellung';
    case 'OPENED':
      return 'Agreed';
    case 'ISSUED':
      return 'In progress';
    case 'CANCELED':
      return 'Canceled by customer';
    case 'CLOSED':
      return 'Bestellung';
    default:
      return '';
  }
};

const colorStatus = (status: string) => {
  switch (status) {
    case 'WAITING':
      return '#B88500';
    case 'OPENED':
      return '#D9EBDC';
    case 'ISSUED':
      return '#111111';
    case 'CANCELED':
      return '#FBE6E8';
    case 'CLOSED':
      return '#D9EBDC';
    default:
      return '';
  }
};

const repeatOrder = (id: number) => {
  statusStore.repeatOrder(id).then((update) => {
    popupStore.changeOpenPopupStore(update);
    shoppingCartStore.getCart();
  });
};
</script>

<style src="./StatusCardComponent.scss" lang="scss" />
