import { Translate } from "./type";

export const French:Translate = {
    'email is a required field': "L'email est un champ obligatoire",
    'password is a required field': "Le mot de passe est un champ obligatoire",
    'copy address': "Copier l'adresse",
    'Workshop and Schuhlungen': 'ateliers et formations',
    kontakt: 'contact',
    partner: 'partenaire',
    geschichte: 'histoire de la maison',
    team: 'équipe',
    'uber uns': 'à propos',
    'workshop & schuhlungen': 'Ateliers et formations',
    '3D druck': 'impression 3D',
    maschinenservice: 'service de machines',
    kataloge: 'catalogues',
    angebot: 'Nos services',
    geschlossen: 'fermé',
    uhr: '',
    'samstag und sonntag': 'samedi et dimanche',
    freitag: 'vendredi',
    'montag - donnerstag': 'lundi - jeudi',
    impressum: 'mentions légales',
    'schuh - und orthopädiebedarf': 'Fournitures pour chaussures et orthopédie',
    'rudolf roth ag': 'Rudolf Roth AG',
    'Anmelden oder Login beantragen': 'demander un accès ou se connecter',
    'Bitte schildern Sie uns Ihr anliegen': "Veuillez nous décrire vos préoccupations",
    logout: 'se déconnecter',
    webshop: 'boutique en ligne',
    schuhlungen: 'Ateliers et formations',
    'unser team': 'notre équipe',
    'mein konto': 'mon compte',
    'erledigte bestellungen': 'Commandes terminées',
    erledigte: 'Commandes terminées',
    'pendente bestellungen': 'commandes en cours',
    pendente: 'Commandes en cours',
    rückgaben: 'retours',
    'defekt melden': 'Formulaire de réclamation',
    accountangaben: 'Informations de compte',
    'änderungen speichern': 'enregistrer les modifications',
    rechnungsadresse: 'adresse de facturation',
    'plz': 'code postal',
    'ein land': 'Pays',
    'die stadt': 'Ville',
    adresse: 'adresse',
    kundennummer: 'numéro de client',
    email: 'email',
    'kennwort ändern': 'changer de mot de passe',
    'neue adresse hinzufügen': 'ajouter une nouvelle adresse',
    kontodetails: 'détails de compte',
    firma: 'entreprise',
    kontaktperson: 'personne de contact',
    telefonnummer: 'numéro de téléphone',
    'zusatzliche telefonnummer': 'numéro de téléphone supplémentaire',
    'gln-nummer': 'numéro GLN',
    'mwst-nummer': 'numéro de TVA',
    lieferadresse: 'Adresse de livraison',
    'bestellung wiederholen': "Repasser la commande",
    'defektbeschreibung1' : 'Description d`un défaut',
    bezahlstatus: "statut de paiement",
    gesamtbetrag: "montant total",
    size: "taille",
    colour: "couleur",
    'melden sie sich in ihrem konto an': 'CONNECTEZ-VOUS À VOTRE COMPTE',
    email_login: 'email',
    'email oder Kundennummer': 'Email ou numéro de client',
    password: 'mot de passe',
    'nicht registriert?': 'Vous n`avez pas de compte?',
    'ein Konto erstellen': 'Demander un accès',
    'passwort vergessen?': 'mot de passe oublié ?',
    'leer': 'vide',
    'wird geladen': 'est en cours de chargement',
    "willkürlich": "arbitraire",
    "festgelegt": "défini",
    "min length 1": "longueur minimale 1",
    'bilder hochladen': 'télécharger des images',
    'bestellnummer': 'Numéro de commande',
    'defektbeschreibung': 'Votre message',
    delete: 'delete',
    'in den warenkorb': 'ajouter au panier',
    'wonach suchst du?': 'que cherchez-vous?',
    category: 'catégorie',
    'leerer warenkorb': 'panier vide',
    'zurück zum einkaufen': 'retour aux achats',
    bestellen: 'commander',
    'zum warenkorb hinzufügen': 'ajouter au panier',
    'menge auf lager':'montant en stock',
    favoritinnen: 'favoris',
    kasse: 'kasse',
    versandart: 'versandart',
    lieferung: 'lieferung',
    'Lieferadresse wählen': 'Choisir l\'adresse de livraison',
    'Andere Adresse': 'Autre adresse', 
    rezension: 'Critique',
    zahlung: 'Paiement',
    "We’re on it!": "Nous nous en occupons !",
    'Your payment has been received and you\'ll get notifications for your order\'s state.': 'Votre numéro de commande  ',
    'payment2': ' a été créé avec succès. Vous recevrez des notifications sur le statut de votre commande.',
    'Zurück zum Einkaufen': 'Retour aux achats',
    'Something went wrong': 'Quelque chose s\'est mal passé',
    'Lorem ipsum dolor sit amet consectetur. Quisque arcu amet pretium lectus viverra sit.': 'Lorem ipsum dolor sit amet consectetur. Quisque arcu amet pretium lectus viverra sit.',
    "versand": "Expédition",
    "post": "Code postal",
    "warenkorb": "Panier",
    "Total": "Total",
    "List": "Liste",
    "Price": "Prix",
    "Quantity": "Quantité",
    "Total Price": "Prix total",
    "color": "Couleur",
    "Rechnung erhalten per": "Recevoir la facture par",
    "Versandart": "Mode d'expédition",
    "List of products": "Liste des produits",
    "Photo": "Photos",
    "Warenkorb": "Panier",
    'Unsere Partner': 'nos partenaires',
    'Schulungen': 'Ateliers et formations',
    Kataloge: 'Catalogues',
    Hauptkatalog: 'catalogue principal',
    Gummi: 'caoutchouc',
    Orthopädie: 'orthopédie',
    "Leder": "cuir",
    "Chemie": "chimie",
    "Beschläge": "ferrures",
    "Schuhpflege": "entretien des chaussures",
    "Werkzeuge": "outils",
    "Maschinen": "machines",
    Firma: "Entreprise",
    vorname: "Prénom",
    "Treten sie mit uns in kontakt": "Contactez-nous",
    nachname: "Nom",
    strasse: "Rue",
    'nr.': "N°",
    'nr': "N°",
    postcode: "Code postal",
    ort: "Ville",
    phone: "Téléphone",
    "Sie uns Ihr anliege": "Votre message",
    "Rudolf Roth AG": "Rudolf Roth AG",
    "Schuh - und Orthopädiebedarf": "Fournitures pour chaussures et orthopédie",
    "Telefondienst": "Horaires d`ouverture",
    "Montag - Donnerstag": "Lundi - Jeudi",
    Freitag: "Vendredi",
    "7.45 Uhr- 12.00 Uhr": "7h45 - 12h00",
    "13.00 Uhr - 17.15 Uhr": "13h00 - 17h15",
    "13.00 Uhr - 16.45 Uhr": "13h00 - 16h45",
    "Samstag und Sonntag": "Samedi et Dimanche",
    Geschlossen: "Fermé",
    Senden: "Envoyer",
    "Maschinenservicek": "Service de maintenance des machines",
    "Ein Maschinenservice-Anforderungsformular": "Formulaire de demande de service de machines",
    "name": "nom",
    "maschinenbezeichnung": "marque de la machine",
    "Maschinennummer": "numéro de la machine",
    "Problem Beschreibung": "Votre message",
    "Anfrage":"Votre message",
    "Maschinen service": "service de machines",
    "Workshop and Schulungen": 'Ateliers et formations',
    kataloge_1: "Si vous ne trouvez pas ce que vous cherchez dans notre boutique en ligne, n'hésitez pas à explorer les catalogues de nos partenaires pour découvrir davantage d'options. Nous serons ravis de commander les articles directement pour vous. Pour toute commande personnalisée, consultez le catalogue de la marque Rudolf Roth AG et contactez-nous pour toute demande spécifique.",
    'Anfrageformular für einen Workshop': 'Formulaire de demande d`atelier',
    'Anfrageformular für 3D-Druck': 'Formulaire de demande d`impression 3D',
    "Geschichte_1": "Depuis 1949, la société Rudolf Roth AG, entreprise familiale de tradition, est synonyme de qualité supérieure et d'un excellent service client. Dès le début, notre objectif a été non seulement de convaincre par des produits, marques et machines de haute qualité, mais aussi par l'engagement et la passion qui sont enracinés dans notre culture d'entreprise. Notre expertise transmise sur trois générations se reflète dans chaque détail et fait de nous un partenaire fiable pour les ateliers orthopédiques, les cordonniers, ainsi que pour les magasins de chaussures et de sport.",
    "Geschichte_2": "Nous attachons une grande importance à la collaboration partenariale, au service personnalisé et visons à établir des relations clients à long terme. Depuis notre site à Gwatt-Thun, nous accompagnons principalement nos clients à l`échelle nationale, en leur offrant des conseils spécialisés et un soutien sur place, tout en proposant des services tels que la vente de produits, les réparations et les révisions.",
    "Geschichte_3": "En tant qu'une des entreprises de gros leaders en Suisse dans notre domaine, notre nom est également synonyme d'innovation et de durabilité. Nous offrons entre autres l'impression 3D, la construction 3D et le recyclage de matériaux EVA pour la construction et le fraisage.Un remerciement particulier à nos clients de longue date, qui nous font confiance depuis des décennies. Ils nous inspirent à continuer à défendre la qualité et le service avec passion et engagement. Nous nous réjouissons de continuer à être votre partenaire fiable en tant qu'entreprise familiale, avec cœur, expérience, tradition et innovation dans les années à venir.",
    'Ihr Warenkorb': 'Votre Panier',
    'Nettobetrag': 'Montant Net',
    'MWST':'TVA',
    'Stärke':'Force',
    'Shore':'Rive',
    'Maßeinheiten':'Dimensions',
    'WAITING': 'EN ATTENTE',
    'OPENED': 'OUVERT',
    'ISSUED': 'ÉMIS',
    'CANCELED': 'ANNULÉ',
    'CLOSED': 'FERMÉ',
    'NORMAL': 'NORMAL',
    'COMPLETED': 'COMPLÉTÉ',
    welcomeText: "Bienvenue sur notre page 'Formations'!",
    welcomeDescriptionPart1: "Nous sommes ravis de vous proposer des ateliers et formations sur mesure, conçus pour répondre à vos besoins. Que vous souhaitiez optimiser l`utilisation de vos adhésifs, explorer les possibilités des matériaux modernes de fraisage et de construction, ou apprendre à fabriquer des orthèses imprimées en 3D, vous êtes au bon endroit. Notre mission est de vous transmettre les connaissances et compétences nécessaires pour travailler en toute sécurité et avec expertise sur nos produits et services, quel que soit votre niveau d`expérience.",
    welcomeDescriptionPart2: "Lors de ces formations, des techniciens de Renia, Topy et d`autres partenaires nous rejoignent souvent pour partager leur expertise directement sur place. Nous proposons également des formations spécifiques à la vente, notamment dans le domaine des produits d`entretien pour les magasins de chaussures et d’articles de sport. Si vous souhaitez augmenter vos ventes grâce à des produits complémentaires dans votre boutique, nous avons des solutions adaptées pour vous accompagner.",
    welcomeDescriptionPart3: "Afin de mieux répondre à vos attentes, nous vous invitons à remplir notre formulaire en ligne en indiquant votre nom, le nom éventuel de votre entreprise, votre numéro de téléphone, votre adresse e-mail et votre adresse postale. Utilisez le champ 'Message' pour nous faire part de la formation qui vous intéresse ou des thèmes spécifiques que vous souhaitez approfondir. Si vous êtes intéressé(e) par l`obtention de crédits pour ces formations, nous serons heureux de vous fournir toutes les informations nécessaires.",
    'Umsatz im Bereich Pflegeprodukte mit einer Verkaufsschulung steigern':"Je souhaiterais augmenter mes ventes de produits d`entretien pour chaussures",
    welcomeDescriptionPart4: 'Nous sommes impatients de vous accompagner dans votre parcours d`apprentissage et de développer ensemble votre expertise!',
    druckTextPart1:'Bienvenue sur notre page dédiée à la demande d`orthèses sur mesure réalisées par impression 3D ! Nous proposons des solutions personnalisées, fabriquées avec précision pour répondre à vos besoins spécifiques. Pour lancer le processus, merci de remplir le formulaire ci-dessous. Cela nous permettra de recueillir toutes les informations nécessaires afin de concevoir une orthèse parfaitement adaptée à vos attentes.',
    druckTextPart2: 'Les informations suivantes sont nécessaires pour traiter votre demande : votre nom complet, votre numéro de téléphone, votre adresse e-mail et votre adresse postale. Si vous soumettez cette demande au nom d`une entreprise, veuillez également inclure le nom de celle-ci.',
    druckTextPart3: 'Dans le champ prévu à cet effet, décrivez vos besoins en détail. Indiquez, par exemple, l`utilisation prévue de l`orthèse ainsi que toute exigence ou contrainte spécifique, comme le design, le confort ou la mobilité.',
    druckText: 'Choix des matériaux :',
    druckDescriptionPart1: 'Sélectionnez parmi les matériaux suivants celui qui convient le mieux à votre orthèse:',
    druckDescriptionPart2: 'Léger, flexible et durable, idéal pour des applications à faible charge.',
    druckDescriptionPart3: 'Résistant aux chocs, idéal pour un usage quotidien et garanti non toxique, adapté au contact alimentaire',
    druckDescriptionPart4: 'Élastique, souple et résistant à l`usure, parfait pour des composants dynamiques.',
    druckDescriptionPart5: 'Renforcé avec des fibres de carbone pour une rigidité et une résistance maximales, adapté aux applications exigeantes.',
    druckDescriptionPart6: 'Se dispone già di un file stl scansionato della parte da trattare, può caricarlo direttamente attraverso il modulo. in caso contrario, la invitiamo a segnalarcelo nel messaggio, e la contatteremo per organizzare una scansione personalizzata.',
    druckDescriptionPart7: 'Une fois votre demande reçue, notre équipe l`étudiera attentivement. nous vous contacterons rapidement pour discuter des détails et planifier les prochaines étapes. merci de nous faire confiance pour vos besoins en orthèses sur mesure. nous avons hâte de collaborer avec vous pour concevoir une orthèse parfaitement adaptée à vos attentes !',
    druckDescriptionPart8: 'PP (Polypropylène):',
    druckDescriptionPart9: 'TPU (Polyuréthane thermoplastique):',
    druckDescriptionPart10: 'PETG (Polyéthylène téréphtalate Glycol):',
    druckDescriptionPart11: 'PETG-Carbone:',
    druckDescriptionPart12:'',
    HARDO: 'HARDO',
    maschinenserviceTextPart1: 'Merci de remplir le formulaire ci-dessous si vous rencontrez un problème avec votre machine, qu`il s`agisse d`une panne, d`une pièce défectueuse ou d`un entretien, comme le remplacement d`un filtre. Veuillez indiquer le nom de la machine, vos coordonnées, ainsi qu`une description détaillée du problème. Si possible, joignez des photos pour nous aider à évaluer la situation. Notre équipe examinera rapidement votre demande et vous contactera pour convenir d`un rendez-vous avec l`un de nos techniciens.',
    Bestellung: 'Commande',
    'Auf-lager': 'En stock',
    Materialoptionen: 'Matériau pour impression 3D',
    PP: 'PP',
    TPU: 'TPU',
    PETG: 'PETG',
    'PETG-Carbon':'PETG-Carbone',
    Gesamtbestellwert: 'Valeur totale de la commande',
    'phasing out message': 'JUSQU`À ÉPUISEMENT',
    'phasing out message1': 'DES STOCKS',
    success: 'Formulaire soumis avec succès !',
    'success-description' : 'Nos spécialistes vous contacteront sous peu',
    'back_to_shop': 'Retour à la boutique',
    Rabattpreis: 'Prix ​​réduit',
    wrong: 'C`est totalement faux',
    reset_password: "Réinitialiser le mot de passe",
    'confirm new password': "confirmer le nouveau mot de passe",
    vom: 'du',
    team_profession_gl_sales_management: "Direction générale, ventes",
    team_profession_gl_technical_sales: "Direction technique, ventes",
    team_profession_gl_admin_sales: "Direction administrative, ventes",
    team_profession_admin_accounting: "Administration, comptabilité",
    team_profession_sales: "Ventes",
    team_profession_admin: "Administration",
    team_profession_logistics_technical: "Gestion des stock, technique",
    team_profession_3d_construction_production: "Conception 3D, production", 
    team_profession_consulting_logistics: "Conseil spécialisé, logistique",
    team_profession_project_management: "Gestion de projet",
    team_profession_logistics_production: "Logistique, production",
    team_profession_workshop_service: "Atelier mécanique, service",
    agree: 'En passant cette commande, vous acceptez nos ',
    agree1: 'Conditions Générales de Vente et notre',
    agree2: '',
    agree3: 'Politique de Confidentialité.',
    tel: 'tél',
    fax: 'fax',
    problem: 'description du problème',
    no_file_available: 'non disponible en téléchargement',
    your_message_top: 'Votre message',
    your_message: 'Votre message',
    'Passwort zurücksetzen': 'Réinitialiser le mot de passe',
    'Geben Sie Ihre E-Mail-Adresse ein': 'Entrez votre adresse e-mail',
    Zurück: 'Retour',
    'Wird gesendet...': 'Envoi...',
    'Ihr Nachricht': 'Votre message',
    'need choice': 'il faut sélectionner l`e-mail ou la publication',
};